import React, { useState, useEffect } from 'react';
import cuteJwon from './cuteJwon.jpg';
import sexyJwon from './sexyJwon.jpg';
import './App.css';

function App() {
  const [linkPosition, setLinkPosition] = useState({ top: '50%', left: '50%' });
  const [imagePosition1, setImagePosition1] = useState({ top: '50%', left: '50%' });
  const [imagePosition2, setImagePosition2] = useState({ top: '50%', left: '50%' });

  const moveLink = () => {
    const randomX = Math.floor(Math.random() * 80) + 10; // 10% ~ 90% 사이의 값
    const randomY = Math.floor(Math.random() * 80) + 10; // 10% ~ 90% 사이의 값
    setLinkPosition({ top: `${randomY}%`, left: `${randomX}%` });
  };

  const moveImages = () => {
    const randomX1 = Math.floor(Math.random() * 80) + 10; // 첫 번째 이미지 랜덤 X 위치
    const randomY1 = Math.floor(Math.random() * 80) + 10; // 첫 번째 이미지 랜덤 Y 위치
    const randomX2 = Math.floor(Math.random() * 80) + 10; // 두 번째 이미지 랜덤 X 위치
    const randomY2 = Math.floor(Math.random() * 80) + 10; // 두 번째 이미지 랜덤 Y 위치

    setImagePosition1({ top: `${randomY1}%`, left: `${randomX1}%` });
    setImagePosition2({ top: `${randomY2}%`, left: `${randomX2}%` });
  };

  useEffect(() => {
    const interval = setInterval(moveImages, 300); // 1초마다 이미지 이동
    return () => clearInterval(interval); // 컴포넌트 언마운트 시 클리어
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* 첫 번째 이미지 */}
        <div
          className="image-container"
          style={{
            position: 'absolute',
            top: imagePosition1.top,
            left: imagePosition1.left,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img src={cuteJwon} className="App-logo-attration" alt="cute logo" />
        </div>

        {/* 두 번째 이미지 */}
        <div
          className="image-container"
          style={{
            position: 'absolute',
            top: imagePosition2.top,
            left: imagePosition2.left,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img src={sexyJwon} className="App-logo-redirection" alt="sexy logo" />
        </div>

        {/* 링크 */}
        <div
          className="link-container"
          style={{
            position: 'absolute',
            top: linkPosition.top,
            left: linkPosition.left,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <h1>
            <a
              className="App-link"
              href="https://github.com/JAXPLE"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={moveLink}
            >
              Git Hub Link!
            </a>
          </h1>
        </div>
      </header>
    </div>
  );
}

export default App;
